import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ico_xls from '../images/ico_xls.png';

const SRFT = () => {
  
  const [actualizacion, setActualizacion] = useState("");


  useEffect(() => {
    fetch('/srft.json')
      .then((data) => data.json())
      .then((articulos) => {

        let $divContenedor = document.getElementById("contenedor_tablas");
        $divContenedor.innerHTML = "";
        let $table;
        let $tbody;
        let $trHead;
        let $tdTitulo;
        let $spanTitulo;
        let $tdFormato;
        let $tdArchivo;
        let $trEncabezados;

        for (let i = 0; i < articulos.length; i++) {

          if ("srft" === articulos[i].articulo) {

            $table = document.createElement("table");
            $tbody = document.createElement("tbody");
            $trHead = document.createElement("tr");
            $trEncabezados = document.createElement("tr");
            $tdTitulo = document.createElement("td");
            $spanTitulo = document.createElement("span");
            $tdFormato = document.createElement("td");
            $tdArchivo = document.createElement("td");


            $spanTitulo.innerText = articulos[i].titulo;
            setActualizacion(articulos[i].actualizacion);
            //setArticulo(articulos[i].nombre);
            //setText(articulos[i].descripcion);


            $table.classList.add("tabTrans");
            $table.classList.add("table");
            $table.classList.add("table-hover");
            $table.style.margin = "0px auto";
            $table.width = "100%";
            $table.setAttribute("cellspacing", "0");
            $table.setAttribute("cellpadding", "0");

            $tdTitulo.setAttribute("colspan", "3");
            $tdTitulo.style.height = "50px";
            $tdTitulo.style.border = "1px solid #DCDCDC";
            $tdTitulo.style.textAlign = "center";
            $tdTitulo.style.backgroundColor = "#F2F2F2";
            $tdTitulo.style.fontSize = "1.3rem";

            $spanTitulo.style.color = "#2d62bf";

            $tdFormato.innerText = "Formato";
            $tdArchivo.innerText = "Documento";

            $tdFormato.style.height = "50px";
            $tdFormato.style.width = "120px";
            $tdFormato.style.maxWidth = "120px";
            $tdFormato.style.border = "1px solid #DCDCDC";
            $tdFormato.style.textAlign = "center";

            $tdArchivo.style.textAlign = "leaft";
            $tdArchivo.style.border = "1px solid #DCDCDC";
            $tdArchivo.style.paddingLeft = "10px";

            $tdTitulo.appendChild($spanTitulo);
            $trHead.appendChild($tdTitulo);

            $trEncabezados.appendChild($tdFormato);
            $trEncabezados.appendChild($tdArchivo);


            $tbody.appendChild($trHead);
            $tbody.appendChild($trEncabezados);

            for (let j = 0; j < articulos[i].archivos.length; j++) {
              //console.log(articulos[i].archivos[j].texto);

              let $trArchivo = document.createElement("tr");
              let $tdContenidoFormato = document.createElement("td");
              let $tdContenidoArchivo = document.createElement("td");
              let $aArchivo = document.createElement("a");
              let $imgArchivo = document.createElement("img");
              let $aTexto = document.createElement("a");

              $aTexto.setAttribute("href", articulos[i].archivos[j].ruta);
              $aTexto.innerText = articulos[i].archivos[j].texto;
              $aTexto.setAttribute("target", "_blank");
              $aTexto.style.color = "#000";

              //$tdContenidoArchivo.innerText = articulos[i].archivos[j].texto;

              $tdContenidoFormato.style.height = "50px";
              $tdContenidoFormato.style.textAlign = "center";
              $tdContenidoFormato.style.border = "1px solid #DCDCDC";

              $tdContenidoArchivo.style.textAlign = "leaft";
              $tdContenidoArchivo.style.border = "1px solid #DCDCDC";
              $tdContenidoArchivo.style.paddingLeft = "10px";

              $aArchivo.setAttribute("href", articulos[i].archivos[j].ruta);
              $aArchivo.setAttribute("target", "_blank");

              //$aArchivo.innerText = articulos[i].archivos[j].formato;
              //$aArchivo.style.color = "#000";
              $imgArchivo.src = ico_xls;
              $aArchivo.appendChild($imgArchivo);

              $tdContenidoFormato.appendChild($aArchivo);

              $tdContenidoArchivo.appendChild($aTexto);

              $trArchivo.appendChild($tdContenidoFormato);
              $trArchivo.appendChild($tdContenidoArchivo);

              $tbody.appendChild($trArchivo);
            }

            $table.appendChild($tbody);
            $divContenedor.appendChild($table);


            if (articulos[i].segundoNivel.length > 1) {
              segundoNivel(articulos[i].segundoNivel);
            }

          }

        }


      });
  }, []);


  const segundoNivel = (data) => {

    let $divContenedor = document.getElementById("segundo_nivel");
    $divContenedor.innerHTML = "";
    let $table;
    let $tbody;
    let $trHead;
    let $tdTitulo;
    let $spanTitulo;
    let $tdFormato;
    let $tdArchivo;
    let $trEncabezados;

    $table = document.createElement("table");
    $tbody = document.createElement("tbody");
    $trHead = document.createElement("tr");
    $trEncabezados = document.createElement("tr");
    $tdTitulo = document.createElement("td");
    $spanTitulo = document.createElement("span");
    $tdFormato = document.createElement("td");
    $tdArchivo = document.createElement("td");


    $spanTitulo.innerText = data[0].titulo;

    $table.classList.add("tabTrans");
    $table.classList.add("table");
    $table.classList.add("table-hover");
    $table.style.margin = "0px auto";
    $table.width = "100%";
    $table.setAttribute("cellspacing", "0");
    $table.setAttribute("cellpadding", "0");

    $tdTitulo.setAttribute("colspan", "3");
    $tdTitulo.style.height = "50px";
    $tdTitulo.style.border = "1px solid #DCDCDC";
    $tdTitulo.style.textAlign = "center";
    $tdTitulo.style.backgroundColor = "#F2F2F2";
    $tdTitulo.style.fontSize = "1.3rem";

    $spanTitulo.style.color = "#2d62bf";

    $tdFormato.innerText = "Formato";
    $tdArchivo.innerText = "Documento";

    $tdFormato.style.height = "50px";
    $tdFormato.style.width = "120px";
    $tdFormato.style.maxWidth = "120px";
    $tdFormato.style.border = "1px solid #DCDCDC";
    $tdFormato.style.textAlign = "center";

    $tdArchivo.style.textAlign = "leaft";
    $tdArchivo.style.border = "1px solid #DCDCDC";
    $tdArchivo.style.paddingLeft = "10px";

    $tdTitulo.appendChild($spanTitulo);
    $trHead.appendChild($tdTitulo);

    $trEncabezados.appendChild($tdFormato);
    $trEncabezados.appendChild($tdArchivo);

    $tbody.appendChild($trHead);
    $tbody.appendChild($trEncabezados);

    for (let i = 0; i < data.length -1; i++) {
      let $trArchivo = document.createElement("tr");
      let $tdContenidoFormato = document.createElement("td");
      let $tdContenidoArchivo = document.createElement("td");
      let $aArchivo = document.createElement("a");
      let $imgArchivo = document.createElement("img");
      let $aTexto = document.createElement("a");

      $aTexto.setAttribute("href", data[i].ruta);
      $aTexto.innerText = data[i].texto;
      $aTexto.setAttribute("target", "_blank");
      $aTexto.style.color = "#000";

      //$tdContenidoArchivo.innerText = data[i].texto;

      $tdContenidoFormato.style.height = "50px";
      $tdContenidoFormato.style.textAlign = "center";
      $tdContenidoFormato.style.border = "1px solid #DCDCDC";

      $tdContenidoArchivo.style.textAlign = "leaft";
      $tdContenidoArchivo.style.border = "1px solid #DCDCDC";
      $tdContenidoArchivo.style.paddingLeft = "10px";

      $aArchivo.setAttribute("href", data[i].ruta);
      $aArchivo.setAttribute("target", "_blank");
      //$aArchivo.innerText = data[i].formato;
      //$aArchivo.style.color = "#000";
      $imgArchivo.src = ico_xls;
      $aArchivo.appendChild($imgArchivo);

      $tdContenidoFormato.appendChild($aArchivo);

      $trArchivo.appendChild($tdContenidoFormato);

      $tdContenidoArchivo.appendChild($aTexto);
      $trArchivo.appendChild($tdContenidoArchivo);

      $tbody.appendChild($trArchivo);
    }

    $table.appendChild($tbody);
    $divContenedor.appendChild($table);

  };

  return (
    <>
      <section id="grl_contenido">
        <div style={{ marginTop: "-200px" }}>
          <div style={{ clear: "both" }}></div>
          <div className="con1">
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_encabezado">
                  {/*
                  <div className='encabezado'>    
                    <NavLink to="/transparencia">
                      <h2>Transparencia
                        <span className='pib'>|</span>
                      </h2>
                    </NavLink>
                  </div>
                  <div id="ContentPlaceHolder1_linksurl">
                    <div className='linksurl'>
                      <NavLink to="/transparencia">
                        <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                      </NavLink>
                    </div>
                  </div>
                  */}
                  <div>
                    <p style={{ fontSize: "1.5rem", color: "#2d62bf" }}>
                      SRFT
                    </p>
                    <p style={{ fontSize: "1.2rem" }}>
                      Reporte Trimestral del Sistema de Recursos Federales Transferidos
                    </p>
                  </div>
                </div>

                <hr />
                <div id="ContentPlaceHolder1_contenido_bd">

                  <div className="table-responsive">
                    <p style={{ textAlign: "right" }}>Información Actualizada a
                      <span style={{ color: "#ED049B" }}>
                        <b> {actualizacion}</b>
                      </span>
                    </p>
                  </div>

                  <div className="table-responsive" id="contenedor_tablas">


                    <br /><br />

                  </div>

                  <div className="table-responsive" id="segundo_nivel">


                    <br /><br />

                  </div>

                </div>

              </section>
            </div>
          </div>

        </div>
      </section>
    </>
  );

}

export default SRFT;