import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const LicitacionesEspecial = () => {
  return (
    <>
      <div className="table-responsive">

        <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td colSpan="5" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                <span style={{ color: "#2d62bf", fontSize: "1.3rem" }}>Convocatoria Pública Federal 002-23</span>
              </td>
            </tr>

            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                LO-801001996-E5-2023
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Anexos-federales-2023-LO-801001996-E5.zip' target='_blank'>Anexos</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Bases-CONV-FED-002-23-LO-801001996-E5.pdf' target='_blank'>Bases</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Publicacion-DOF-conv-002-23-LO-801001996-E5.pdf' target='_blank'>Convocatoria</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Minuta de la Junta de Aclaración de Dudas LO-801001996-E5-2023
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/min-jta-acla-conv-oub-fed-23-LO-801001996-E5-2023.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>

            <tr>
              <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                LO-801001996-E6-2023
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Anexos-federales-2023-LO-801001996-E6.zip' target='_blank'>Anexos</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Bases-CONV-FED-002-23-LO-801001996-E6.pdf' target='_blank'>Bases</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Publicacion-DOF-conv-002-23-LO-801001996-E6.pdf' target='_blank'>Convocatoria</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Minuta de la Junta de Aclaración de Dudas LO-801001996-E6-2023
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/min-jta-acla-conv-oub-fed-23-LO-801001996-E6-2023.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Acta de Apertura Técnica Económica
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/acta-ap-tec-eco-conv-pub-fed-002-23.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Acta de Fallo y Adjudicación
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/acta-fallo-y-adj-conv-pub-fed-002-23.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            

            <tr>
              <td colSpan="4" style={{ textAlign: "justify", border: "1px solid #DCDCDC", padding: "20px" }}>
                <span>A todos los interesados en participar en las licitaciones favor de mandar un correo electrónico a <b style={{ fontWeight: "bold" }}>licitaciones@ccapama.gob.mx</b> en donde manifiesta su intencion en participar, numero de convocatoria, numero de licitacion, nombre de la obra, nombre de la empresa o persona fisica</span>
              </td>
            </tr>

          </tbody>
        </table>

        <br /><br />


        <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td colSpan="5" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                <span style={{ color: "#2d62bf", fontSize: "1.3rem" }}>Convocatoria Adquisición Ccapama 001-2023</span>
              </td>
            </tr>

            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Convocatoria Adquisición Ccapama
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Catalogo-de-partidas-adq-001-2023.xlsx' target='_blank'>Anexos</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Bases-de-licitación-ADQ-001-23.docx' target='_blank'>Bases</a>
              </td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/Conv-DOF-AD-001-23.pdf' target='_blank'>Convocatoria</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Minuta Junta de Aclaraciones
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/min-jta-acla-LA-801001996-E7-2023.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Acta de Apertura Técnica Económica
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/acta-ap-tec-eco-adq-ccap-001-23.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Acta de Comité
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/acta_de_comite_ad-ccap-001-23.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>
            <tr>
              <td style={{ height: "50px", width: "600px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                Acta de Fallo y Adjudicación
              </td>
              <td colSpan="2" style={{ textAlign: "center", border: "1px solid #DCDCDC" }}></td>
              <td style={{ textAlign: "center", border: "1px solid #DCDCDC" }}>
                <a href='/licitaciones/acta_fallo_y_adj_adq-ccap-001-23.pdf' target='_blank'>Descargar</a>
              </td>
            </tr>

            <tr>
              <td colSpan="4" style={{ textAlign: "justify", border: "1px solid #DCDCDC", padding: "20px" }}>
                <span>A todos los interesados en participar en las licitaciones favor de mandar un correo electrónico a <b style={{ fontWeight: "bold" }}>paulo.avila@ccapama.gob.mx</b> en donde manifiesta su intención en participar, numero de convocatoria, numero de licitación, objeto de la compra, nombre de la empresa o persona fisica.</span>
              </td>
            </tr>
          </tbody>
        </table>

        <br /><br />

      </div>
    </>
  );
}

export default LicitacionesEspecial;