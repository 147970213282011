import React from 'react';
import { NavLink } from 'react-router-dom';

const DifusionDeAcciones = () => {
  return (
    <>
      <section id="grl_contenido">
        <div style={{ marginTop: "-200px" }}>
          <div style={{ clear: "both" }}></div>
          <div className="con1">
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_encabezado">
                  <div className='encabezado'>
                    <h2>Difusión de Acciones
                      <span className='pib'>|</span>
                    </h2>
                  </div>
                </div>
                <div id="ContentPlaceHolder1_linksurl">
                  <div className='linksurl'>
                    <NavLink to="/">
                      <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                    </NavLink>
                  </div>
                </div>
                <hr />
                <div id="ContentPlaceHolder1_contenido_bd">

                  <div className="table-responsive" id="contenedor_tablas">

                    <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                            <span style={{ color: "#2d62bf" }}>Acciones realizadas con recursos del ramo 33 fondo 3</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/fism-2018.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            FISM 2018
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/fism-2016.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            FISM 2016
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/fism-2015.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            FISM 2015
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/fism-2014.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            FISM 2014
                          </td>
                        </tr>

                      </tbody>
                    </table>

                    <br /><br />

                  </div>
                </div>

              </section>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default DifusionDeAcciones;