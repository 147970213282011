import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import btn_vm_over from "../images/home/btn_vm_over.png";
import img9 from "../images/boletines/img9.jpg";
import img8 from "../images/boletines/img8.jpg";
import img7 from "../images/boletines/img7.jpg";

const Boletines = () => {

  const [contenido, setContenido] = useState({});

  const getBoletines = () => {
    fetch('/boletines.json')
      .then((r) => r.json())
      .then((data) => {
        setContenido(data);
      })
  };

  useEffect(() => {
    //getBoletines();
  }, []);


  useEffect(() => {

    let $ul = document.getElementById("ul-boletines");
    let $li;
    let $div1;
    let $img;
    let $a;
    let $div2;
    let $h4;
    let $a2;

    let maxBoletines = 0;

    if (contenido.length > 0) {

      if (contenido.length >= 3)
        maxBoletines = 3;
      else
        maxBoletines = contenido.length;

      for (let i = 0; i < maxBoletines; i++) {
        $li = document.createElement("li");
        $div1 = document.createElement("div");
        $div1.setAttribute("id", "contBoletines_alineados");
        $a = document.createElement("a");
        $img = document.createElement("img");

        if (contenido[i].imagen === "img1")
          $img.setAttribute("src", img7);

        if (contenido[i].imagen === "img2")
          $img.setAttribute("src", img7);

        if (contenido[i].imagen === "img3")
          $img.setAttribute("src", img7);

        $a.setAttribute("href", "/boletines/" + contenido[i].id);
        $div2 = document.createElement("div");
        $a2 = document.createElement("a");
        $a2.setAttribute("href", "/boletines/" + contenido[i].id);
        $h4 = document.createElement("h4");
        $h4.innerText = contenido[i].titulo;

        $a2.appendChild($h4);
        $div2.appendChild($a2);
        $a.appendChild($img);
        $div1.appendChild($a);
        $div1.appendChild($div2);
        $li.appendChild($div1);
        $ul.appendChild($li);
      }
    }

  }, [contenido])



  return (
    <>
      <div className="cell cell-3">
        <div className="verticalCarousel">
          <div className="verticalCarouselTit"><h2>Sala de Prensa</h2></div>

          <ul className="verticalCarouselGroup vc_list" id="ul-boletines">

            <li>
              <div id="contBoletines_alineados">
                <NavLink to="/boletines/B-00058-23"><img src={img7} alt="Boletin" /></NavLink>
                <div>
                  <h4>EL PLAN MAESTRO DEL AGUA GARANTIZA EFICIENCIA Y AHORROS CON VISIÓN A FUTURO</h4>
                </div>
              </div>
            </li>

            <li>
              <div id="contBoletines_alineados">
                <NavLink to="/boletines/B-00056-23"><img src={img8} alt="Boletin" /></NavLink>
                <div>
                  <h4>CCAPAMA MEJORA LA EFICIENCIA EN 5 POZOS Y RECUPERA CAUDAL</h4>
                </div>
              </div>
            </li>

            <li>
              <div id="contBoletines_alineados">
                <NavLink to="/boletines/B-00052-23"><img src={img9} alt="Boletin" /></NavLink>
                <div>
                  <h4>MUNICIPIO CONSOLIDA SU COMPROMISO HACIA LA DEFENSA DE LOS USUARIOS DE AGUA POTABLE EN LA CAPITAL </h4>
                </div>
              </div>
            </li>

          </ul>
          <div className="verticalCarouselHeader">
            <div id="boletines_mas">
              <NavLink to="/boletines/B-00037-23">
                <img src={btn_vm_over} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Boletines;