import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import logoCcapama from "../images/logo-web-color.png";

const Header = () => {

  let estadoMenu = false;

  const clicMenu = (e) => {

    let menu = document.getElementById("menuBars");
    menu.classList.toggle("change");


    if (estadoMenu) {
      document.getElementById("menuFull").style.display = "none";
      estadoMenu = false;
    } else {
      document.getElementById("menuFull").style.display = "block";
      estadoMenu = true;
    }

  };


  return (
    <>
      <header>
        <div id="grl_menu">
          <div id="grl_menu_logo">
            <NavLink id="logoCcapam" to="/">
              <img alt="logotipo ccapama" src={logoCcapama} />
            </NavLink>
          </div>
          <div id="grl_menu_botonera">
            <ul>
              <li className="movHidden">
                <a href="https://es-la.facebook.com/CCAPAMA/" target="_blank" rel="noreferrer"><img alt="Facebook" src="https://www.ags.gob.mx/sitio_imgs/ico_menu_redes_face.png" /></a>
              </li>
            </ul>
          </div>

          {/*
          <div id="grl_menu_busqueda">
            <input name="ctl00$txt_busquedaGrl" type="text" id="txt_busquedaGrl" className="box_busquedaGrl" placeholder="Buscar en el portal" />
            <input type="submit" name="ctl00$btn_busquedaGrl" value="" id="btn_busquedaGrl" className="btn_busquedaGrl" />
          </div>
          */}

          <div id="grl_menu_menu">
            <div id="menuBars" className="menuIcon" onClick={clicMenu}>
              <div id="menuBar" style={{ position: "relative", width: "100%", minHeight: "10px", float: "left", textAlign: "center", paddingLeft: "6px !important" }}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
              <div style={{ width: "100%", height: "8px !important", float: "left" }}><h4>Menú</h4></div>
            </div>
          </div>
        </div>
      </header>


      <div id="menuFull" className="animate__animated animate__fadeInDown">
        <div className="menuside" id="menu_sec">
          <div className="containerMenuFull">

            <div className="cell cell-menu2">
              <h2><span onClick={clicMenu} style={{ cursor: "pointer" }}>CCAPAMA</span></h2>
              <hr />
              <ul>
                <li>
                  <NavLink id="quienesSomos" to="/ccapama" onClick={clicMenu}>
                    <div className="menu_alineados">
                      <span>¿Quiénes Somos?</span>
                    </div>
                  </NavLink>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/directorio.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      <span>Directorio</span>
                    </div>
                  </a>
                </li>
                <li>
                  <NavLink to="/contacto" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Contacto</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/difusionDeAcciones" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Difusión de Acciones</span>
                    </div>
                  </NavLink>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/codigo-etica.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Código de Ética</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/codigo-conducta.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Código de Conducta</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/modelo-marco-integrado-control-interno.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "20px" }}>
                      •
                      <span>Modelo de Marco Integrado de Control Interno</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="cell cell-menu2">
              <h2><span onClick={clicMenu} style={{ cursor: "pointer" }}>Servicios</span></h2>
              <hr />
              <ul>
                <li>
                  <a href="https://www.veolia.com.mx/ags/pago-linea" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Pago del Recibo del Agua</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <NavLink to="/licitaciones" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Licitaciones</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <a href="/archivos/padron-proveedores.pdf" targte="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Proveedores</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="">
                    <div className="menu_alineados">
                      •
                      <span>Plan Anual de Adquisiciones 2016</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <NavLink to="/tramites-y-servicios" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Trámites y Servicios</span>
                    </div>
                  </NavLink>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/tarifa.pdf" targte="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "25px" }}>
                      •
                      <span>Costos Unitarios para Pagos de Derechos de Conexión y Extracción</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <a href="https://fas.ccapama.gob.mx" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "35px" }}>
                      •
                      <span>Consulta el Estado de tu Certificado FAS</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="cell cell-menu2">
              <h2><span onClick={clicMenu} style={{ cursor: "pointer" }}>Transparencia</span></h2>
              <hr />
              <ul>
                <li>
                  <NavLink to="/transparencia" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Ley General de Transparencia</span>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <a href="/archivos/titulo.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Título de Concesión</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <a href="/archivos/anexos-titulo.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Anexos Título</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/archivos/IERC-ccapama.xlsx" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Indice de Expedientes Clasificados como Reservados</span>
                    </div>
                  </a>
                </li>
                <li className="hiddenmenulst">
                  <NavLink to="/recursosFederales" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Recursos Federales</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="cell cell-menu2">
              <h2><span onClick={clicMenu} style={{ cursor: "pointer" }}>Aviso de Privacidad</span></h2>
              <hr />
              <ul>
                <li>
                  <a href="/archivos/simplificado-2022.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Simplificado</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/archivos/integral-2022.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "20px" }}>
                      •
                      <span>Integral</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>


            <div className="cell cell-menu2">
              <h2><span onClick={clicMenu} style={{ cursor: "pointer" }}>Sistema Global de Calidad</span></h2>
              <hr />
              <ul>
                <li>
                  <a href="/archivos/manual-calidad.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados">
                      •
                      <span>Manual de Calidad</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/manual_de_procedimientos_octubre_2019.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "20px" }}>
                      •
                      <span>Manual de Procedimientos</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/archivos/documentacion-sgc-1.pdf" target="_blank" onClick={clicMenu}>
                    <div className="menu_alineados" style={{ marginTop: "20px" }}>
                      •
                      <span>Documentación SGC</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </>
  )
};

export default Header;