import React from 'react';
import ico_gnal from "../images/ico_transgral.png";
import btn_vm from "../images/home/btn_vm.png";
import obligaciones from "../images/obligaciones.jpeg";
import obligacionesTransparencia from "../images/transparenciaObligaciones.jpeg";
import { NavLink } from 'react-router-dom';

const BannerTransparencia = () => {
  return (
    <>
      <div className="cell cell-4">

        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

          <div style={{marginTop: "30px", paddingRight: "40px"}}>
            <a target='_blank' href='https://www.plataformadetransparencia.org.mx/'>
              <img src={obligacionesTransparencia} width="250" alt="" />
            </a>
          </div>

          <div style={{marginTop: "30px"}}>
            <a target='_blank' href='https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/?idSujetoObigadoParametro=15934&idEntidadParametro=1'>
              <img src={obligaciones} width="250" alt="" />
            </a>
          </div>
          
        </div>

        <div id="cell_cont4">

        

          <div id="cont4_cont">

            

            {/*
            <h2 style={{ marginTop: "4rem" }}>Transparencia</h2>

            <NavLink to="/transparencia">
              <div className="elementos_alineados_transparencia">
                <img src={ico_gnal} width="50" alt="" />
                <span>Art. 70 de la Ley General de Transparencia y Acceso a la Informacion Publica</span>
              </div>
            </NavLink>

            <NavLink to="/transparencia">
              <div className="elementos_alineados_transparencia">
                <img src={ico_gnal} width="50" alt="" />
                <span>Art. 55 de la Ley de Transparencia y Acceso a la Informacion Publica del Estado de Aguascalientes y sus Municipios</span>
              </div>
            </NavLink>
            */}

            {/*
            <NavLink to="/srft">
              <div className="elementos_alineados_transparencia" style={{marginTop: "1.5rem"}}>
                <img src={ico_gnal} width="50" alt="" />
                <span>Art. 85  Fracc II Párrafo 5º de la Ley Federal de Presupuestos y Responsabilidad Hacendaria</span>
              </div>
            </NavLink>
            */}

            {/*
            <div className="transparenciaMas" style={{ cursor: "pointer" }}>
              <NavLink to="/transparencia">
                <img alt="Ir al sitio" src={btn_vm} />
              </NavLink>
            </div>
            */}
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerTransparencia;