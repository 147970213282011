import React from 'react';
import { NavLink } from 'react-router-dom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const Footer = () => {
  return (
    <>
      <section id="sec_piePagina" className="section fp-auto-height" style={{marginTop: "12rem"}}>
        <div id="pie_mapaSitio" className="pie_divs">
          <div className="mapaSitio_apartado">
            <h4>CCAPAMA</h4>
            <ul>
              <li></li>
              <li><NavLink to="/ccapama">¿Quiénes Somos?</NavLink></li>
              <li><a href="/archivos/directorio.pdf">Directorio</a></li>
              <li><NavLink to="/contacto">Contacto</NavLink></li>
              <li><NavLink to="/difusionDeAcciones">Difusión de Acciones</NavLink></li>
              <li><a href="/archivos/CODIGO-DE-ETICA-DEL-MPIO-DE-AGS.pdf" target="_blank">Código de Ética</a></li>
              <li><a href="/archivos/CODIGO-DE-CONDUCTA-DEL-MUNICIPIO-DE-AGS.pdf" target="_blank">Código de Conducta</a></li>
              <li><a href="/archivos/modelo-marco-integrado-control-interno.pdf" target="_blank">Modelo de Marco Integrado de Control Interno</a></li>
            </ul>
          </div>

          <div className="mapaSitio_apartado">
            <h4>SERVICIOS</h4>
            <ul>
              <li></li>
              <li><a href="https://www.veolia.com.mx/ags/pago-linea" target="_blank">Pago del Recibo del Agua</a></li>
              <li><NavLink to="/licitaciones">Licitaciones</NavLink></li>
              <li><a href="/archivos/padron-proveedores.pdf" targte="_blank">Proveedores</a></li>
              <li><a href="">Plan Anual de Adquisiciones 2016</a></li>
              <li><NavLink to="/tramites-y-servicios">Trámites y Servicios</NavLink></li>
              <li><a href="/archivos/tarifa.pdf" targte="_blank">Costos Unitarios para Pago de Derechos de Conexión y Extracción</a></li>
              <li><a href="https://fas.ccapama.gob.mx" target="_blank">Consulta el Estado de tu Certificado del FAS</a></li>

            </ul>
          </div>

          <div className="mapaSitio_apartado" style={{ clear: "both" }}>
            <h4>TRANSPARENCIA</h4>
            <ul>
              <li></li>
              <li><NavLink to="/transparencia">Ley General de Transparencia</NavLink></li>
              <li><a href="/archivos/titulo.pdf" target="_blank">Título de Concesión</a></li>
              <li><a href="/archivos/anexos-titulo.pdf" target="_blank">Anexos Título</a></li>
              <li><a href="/archivos/IERC-ccapama.xlsx" target="_blank">Índice de Expedientes Clasificados como Reservados</a></li>
              <li><NavLink to="/recursosFederales">Recursos Federales</NavLink></li>
              <li><a href="/archivos/MANUAL_CONTABILIDAD_GUBERNAMENTAL.pdf" target="_blank">Manual de Contabilidad Gubernamental</a></li>
              <li><NavLink to="/contabilidadGubernamental">Contabilidad Gubernamental</NavLink></li>
            </ul>
          </div>

          <div className="mapaSitio_apartado">
            <h4>SITIOS DE INTERES</h4>
            <ul>
              <li></li>
              <li><a href="https://ags.gob.mx/" target="_blank">H. Ayuntamiento de Aguascalientes</a></li>
              <li><a href="https://www.aguascalientes.gob.mx/inagua/" target="_blank">Inagua</a></li>
              <li><a href="https://www.gob.mx/conagua" target="_blank">Conagua</a></li>
            </ul>
          </div>

          <div className="mapaSitio_apartado" style={{ clear: "both" }}>
            <h4>AVISO DE PRIVACIDAD</h4>
            <ul>
              <li></li>
              <li><a href="/archivos/simplificado-2022.pdf" target="_blank">Simplificado</a></li>
              <li><a href="/archivos/integral-2022.pdf" target="_blank">Integral</a></li>
            </ul>
          </div>

          <div className="mapaSitio_apartado">
            <h4>SISTEMA GLOBAL DE CALIDAD</h4>
            <ul>
              <li></li>
              <li><a href="/archivos/manual-calidad.pdf" target="_blank">Manual de Calidad</a></li>
              <li><a href="/manual_de_procedimientos_octubre_2019.pdf" target="_blank">Manual de Procedimientos</a></li>
              <li><a href="/archivos/documentacion-sgc-1.pdf" target="_blank">Documentación SGC</a></li>
            </ul>
          </div>


        </div>

        <div id="pie_Twitter" className="pie_divs">
          <div id="grl_menu_botonera" className="redesPie">
            <ul>
              <li>
                <a href="https://es-la.facebook.com/CCAPAMA/" target="_blank"><img alt="WhatsApp" src="https://www.ags.gob.mx/sitio_imgs/ico_menu_redes_face.png" /></a>
              </li>
            </ul>
          </div>
          <div id="pie_twitter_widget">
            <TwitterTimelineEmbed
              sourceType="profile"
              userId={"435880688781234176"}
              options={{ height: 400 }}
            />
            <a className="twitter-timeline" href="https://twitter.com/MunicipioAgs" height='660px' width='100%' data-chrome="noborders  nofooter transparent noscrollbar" data-tweet-limit="4" data-link-color="#3d87e6" data-theme="light" data-widget-id="435880688781234176">Tweets por @MunicipioAgs</a>

          </div>
        </div>


        <div id="pie_Contacto" className="pie_divs">
          <hr />
          <p>Comisión Ciudadana de Agua Potable y Alcantarillado del Municipio de Aguascalientes <span className="colorResalte">2021 - 2024</span>. Dr. Salvador Quezada Limón #1407, Col. San MArcos, Aguascalientes, Ags. CP. 20070.</p>
        </div>


      </section>
    </>
  );
}

export default Footer;