import React from 'react';
import quienes_somos from "../images/quienes_somos.jpg";

const Ccapama = () => {
  return (
    <>
      <section id="grl_contenido">
        <div>
          <div style={{clear: "both"}}></div>
          <div className="con1" style={{marginTop: "40px"}}>
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_contenido_bd">
                  <div align="justify">
                    <div align="justify">
                      <img style={{objectFit:"cover", width:"100%", height: "450px"}} alt="" src={quienes_somos} />
                      <p className="boletines" style={{fontSize: "1.5rem"}}>
                        ¿QUIÉNES SOMOS?
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        Somos un organismo ciudadano, técnico, público, descentralizado de la administración municipal, con personalidad jurídica y patrimonio propio, con características de permanencia, autonomía y comprometido con la equidad de género; con funciones de normatividad, autoridad administrativa, evaluación, coordinación y vigilancia de las acciones de Proactiva Medio Ambiente CAASA como ente concesionario encargado de operar el servicio de agua potable y alcantarillado en el municipio de Aguascalientes.
                      </h6>
                      <p className="boletines" style={{fontSize: "1.5rem", marginTop: "5rem"}}>
                        Nuestro Compromiso
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        Regular y garantizar los servicios de agua potable, alcantarillado y saneamiento, a los asentamientos humanos del Municipio de Aguascalientes, con la calidad, cantidad y continuidad que permitan alcanzar el nivel y dignidad de vida demandados por la comunidad , con tarifas equitativas y en forma sustentable; así como normar y supervisar la prestación de los servicios públicos de agua potable, alcantarillado y saneamiento y la infraestructura hidráulica respectiva, de conformidad con el Artículo 20 de la Ley.
                        <br /><br />
                        Llevar a cabo la supervisión, control, evaluación, intervención, normatividad y asistencia a los concesionarios o contratantes, a fin de que la construcción, administración, operación, mantenimiento y desarrollo de los sistemas de agua potable, alcantarillado y saneamiento se realicen cumpliendo estrictamente los lineamientos de eficiencia y calidad que se establezcan en la Ley, en el Reglamento y los que establezca el propio Consejo Directivo de la Comisión.
                      </h6>
                      <p className="boletines" style={{fontSize: "1.5rem", marginTop: "5rem"}}>
                        Nuestra Misión
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        Regular, vigilar y garantizar la prestación de los servicios de agua potable, alcantarillado y saneamiento;de manera equitativa, sustentable y participativa.
                      </h6>
                      <p className="boletines" style={{fontSize: "1.5rem", marginTop: "5rem"}}>
                        Nuestra Visión
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        Hacer del Municipio de Aguascalientes, una comunidad con servicios sustentables de agua potable, alcantarillado y saneamiento de calidad, en cantidad suficiente y continuidad permanente. Con un Consejo Directivo Ciudadano comprometido con el servicio a la población, supervisando y coadyuvando con un concesionario respetuoso del Título de Concesión y una sociedad participativa, vigilante y comprometida con la cultura del agua, la equidad de género y la sustentabilidad.
                      </h6>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ccapama;