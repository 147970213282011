import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const RecursosFederales = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section id="grl_contenido">
        <div style={{ marginTop: "-200px" }}>
          <div style={{ clear: "both" }}></div>
          <div className="con1">
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_encabezado">
                  <div className='encabezado'>
                    <h2>Recursos Federales
                      <span className='pib'>|</span>
                    </h2>
                  </div>
                </div>
                <div id="ContentPlaceHolder1_linksurl">
                  <div className='linksurl'>
                    <NavLink to="/">
                      <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                    </NavLink>
                  </div>
                </div>
                <hr />
                <div id="ContentPlaceHolder1_contenido_bd">

                  <div className="table-responsive" id="contenedor_tablas">

                    <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                            <span style={{ color: "#2d62bf" }}>Recursos Federales</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/1er-Trim-2019-Recursos-Federales.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            1er Trim 2019 Recursos Federales
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/3er-trim-2019-prodder.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            3er Trim 2019 Recursos Federales
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/Ccapama_1er.Trim.2020_Transp.Presup.SRFT_Mpio.Ags._Prodder2019_22a_04may2020_20200508.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            1er Trim 2020 Transparencia Presupuestaria SRFT SHCP Mpio Ags - CCAPAMA Prodder 2019 22a
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/Ccapama_1er.Trim.2020_Transp.Presup.SRFT_Mpio.Ags._Prodder2019_22d_04may2020_20200508.pdf" target="_blank" style={{ color: "#000" }}>PDF</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            1er Trim 2020 Transparencia Presupuestaria SRFT SHCP Mpio Ags - CCAPAMA Prodder 2019 22d
                          </td>
                        </tr>

                      </tbody>
                    </table>

                    <br /><br />

                  </div>


                  <div className="table-responsive" id="contenedor_tablas">

                    <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                            <span style={{ color: "#2d62bf" }}>Destino del Gasto</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/REPORTE-TRIMESTRAL-DE-RECURSOS-FEDERALES-TRASFERIDOS-2DO-TRIMESTRE-2022-2023.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            2do Trim 2023
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/REPERTE_TRIMESTRAL_DE_RECURSOS_FEDERALES TRASFERIDOS_1er_TRIMESTRE_2023.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            1er Trim 2023
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/REPORTE_TRIMESTRAL_DE RECURSOS_FEDERALES_TRASFERIDOS_4to_TRIMESTRE_2022.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            4to Trim 2022
                          </td>
                        </tr>


                      </tbody>
                    </table>

                    <br /><br />

                  </div>



                  <div className="table-responsive" id="contenedor_tablas">

                    <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                            <span style={{ color: "#2d62bf" }}>Ejercicio del Gasto</span>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/FINANZAS_2do_Trim_2023.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            2do Trim 2023
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/FINANZAS_1er_trim_2023.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            1er Trim 2023
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            <a href="/archivos/FINANZAS_4o_trim_2022.xlsx" target="_blank" style={{ color: "#000" }}>XLSX</a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC", paddingLeft: "10px" }}>
                            4to Trim 2022
                          </td>
                        </tr>

                        

                      </tbody>
                    </table>

                    <br /><br />

                  </div>


                </div>

              </section>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default RecursosFederales;