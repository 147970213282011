import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import img7 from "../images/boletines/img7.jpg";
import img8 from "../images/boletines/img8.jpg";
import img9 from "../images/boletines/img9.jpg";

const valoresIniciales = {
  "id": "",
  "no": "",
  "titulo": "",
  "extracto": "",
  "parrafo1": "",
  "parrafo2": "",
  "parrafo3": "",
  "parrafo4": "",
  "parrafo5": "",
  "imagen": "",
  "fecha": ""
}

const Boletines = () => {

  let { noBoletin } = useParams();
  const [boletines, setBoletines] = useState({});
  const [boletinInfo, setBoletinInfo] = useState(valoresIniciales);

  const getBoletines = () => {
    fetch('/boletines.json')
      .then((r) => r.json())
      .then((data) => {

        setBoletines(data);

        if (noBoletin === "0") {

          let $divCaja = document.getElementById("boletin_caja");
          let $div;
          let $center;
          let $aImahgen;
          let $img;
          let $p;
          let $aTexto;

          for (let i = 0; i < boletines.length; i++) {
            $div = document.createElement("div");
            $center = document.createElement("center");
            $aImahgen = document.createElement("a");
            $img = document.createElement("img");
            $p = document.createElement("p");
            $aTexto = document.createElement("a");

            $div.setAttribute("id", "caja");
            $aImahgen.setAttribute("href", "/boletines/" + boletines[i].id);

            $img.setAttribute("src", boletines[i].imagen);
            $img.classList.add("boletinprensa");

            $p.classList.add("titutloboletin");
            $aTexto.innerText = boletines[i].titulo;
            $aTexto.setAttribute("href", "/boletines/" + boletines[i].id);


            $p.appendChild($aTexto);

            $aImahgen.appendChild($img);
            $center.appendChild($aImahgen);
            $div.appendChild($center);
            $div.appendChild($p);
            $divCaja.appendChild($div);
          }
        }
      })
  };


  useEffect(() => {
    getBoletines();
  }, [noBoletin]);


  useEffect(() => {
    if (boletines && noBoletin !== "0") {
      for (let i = 0; i < boletines.length; i++) {
        if (boletines[i].id === noBoletin) {

          if (noBoletin === "B-00058-23") {
            setBoletinInfo({
              ...boletinInfo,
              "id": boletines[i].id,
              "no": boletines[i].no,
              "titulo": boletines[i].titulo,
              "extracto": boletines[i].extracto,
              "parrafo1": boletines[i].parrafo1,
              "parrafo2": boletines[i].parrafo2,
              "parrafo3": boletines[i].parrafo3,
              "parrafo4": boletines[i].parrafo4,
              "parrafo5": boletines[i].parrafo5,
              "imagen": img7,
              "fecha": boletines[i].fecha
            });
          }

          if (noBoletin === "B-00056-23") {
            setBoletinInfo({
              ...boletinInfo,
              "id": boletines[i].id,
              "no": boletines[i].no,
              "titulo": boletines[i].titulo,
              "extracto": boletines[i].extracto,
              "parrafo1": boletines[i].parrafo1,
              "parrafo2": boletines[i].parrafo2,
              "parrafo3": boletines[i].parrafo3,
              "parrafo4": boletines[i].parrafo4,
              "parrafo5": boletines[i].parrafo5,
              "imagen": img8,
              "fecha": boletines[i].fecha
            });
          }

          if (noBoletin === "B-00052-23") {
            setBoletinInfo({
              ...boletinInfo,
              "id": boletines[i].id,
              "no": boletines[i].no,
              "titulo": boletines[i].titulo,
              "extracto": boletines[i].extracto,
              "parrafo1": boletines[i].parrafo1,
              "parrafo2": boletines[i].parrafo2,
              "parrafo3": boletines[i].parrafo3,
              "parrafo4": boletines[i].parrafo4,
              "parrafo5": boletines[i].parrafo5,
              "imagen": img9,
              "fecha": boletines[i].fecha
            });
          }

        }
      }
    }
  }, [boletines])


  return (
    <>
      <section id="grl_contenido">
        <div>
          <div style={{ clear: "both" }}></div>
          <div className="con1" style={{ marginTop: "40px" }}>
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">

                {noBoletin !== "0" ?
                  <>
                    <div id="ContentPlaceHolder1_encabezado">
                      <div className='encabezado'>
                        <h2>Boletín no.{boletinInfo.no}
                          <span className='pib'>|</span>
                        </h2>
                      </div>
                    </div>
                    <div id="ContentPlaceHolder1_linksurl">
                      <div className='linksurl'>
                        <NavLink to="/">
                          <img src='/images/boletines/linkhome.png' style={{ width: "16px", height: "14px" }} />
                        </NavLink> /
                        <NavLink to="/">
                          Ccapama
                        </NavLink> /
                        <span  style={{ color: "#ed049b" }}>
                          Boletines y Noticias
                        </span>
                      </div>
                    </div>
                    <hr />
                    <div id="ContentPlaceHolder1_contenido_bd">
                      <div align="justify">
                        <h1></h1>
                        <div align="justify">
                          <h1 className='h1boletines'>
                            <strong>{boletinInfo.titulo}</strong>
                          </h1>
                          <img style={{ width: "100%", height: "30rem", objectFit: "cover" }} alt="" src={boletinInfo.imagen} />
                          <p className="boletines">
                            {boletinInfo.extracto}
                          </p>
                          <h6 align='right' style={{ clear: "both" }}>
                            Aguascalientes, Ags.,
                            <span style={{ color: "#ed049b" }}><b>{boletinInfo.fecha}</b></span>
                          </h6>
                          <h6>
                            {boletinInfo.parrafo1}
                            <br /><br />
                            {boletinInfo.parrafo2}
                            <br /><br />
                            {boletinInfo.parrafo3}
                            <br /><br />
                            {boletinInfo.parrafo4}
                            <br /><br />
                            {boletinInfo.parrafo5}
                            <br /><br />
                          </h6>
                        </div>
                      </div>
                    </div>
                  </>
                  : <>
                    <h2>Boletines </h2>

                    <div id="ContentPlaceHolder1_linksurl">
                      <div className='linksurl'>
                        <NavLink to="/">
                          <img src='/images/boletines/linkhome.png' style={{ width: "16px", height: "14px" }} />
                        </NavLink> /
                        <NavLink to="/">
                          Ccapama
                        </NavLink> /
                        <NavLink to="/boletines/0" style={{ color: "#ed049b" }}>
                          Boletines y Noticias
                        </NavLink>
                      </div>
                    </div>

                    <div id="boletin_caja" className="boletines_wrap">

                      {/*
                        <div id="caja">
                          <center>
                            <a href="/cont.aspx?p=9294" target="_parent">
                              <img src="/images/boletines/DSC_9821.jpg" alt="img_boletin" className="boletinprensa" />
                            </a>
                          </center>
                          <p className="titutloboletin">
                            <a href="/cont.aspx?p=9294" target="_parent">
                              CELEBRA MUNICIPIO “DÍA DE REYES” EN COMUNIDADES RURALES CON LA ENTREGA DE ROSCAS Y JUGUETES
                            </a>
                          </p>
                          <div className="encabezadoboletin" style={{marginTop: "-50px"}}>
                            Se visitaron 9 comunidades rurales pertenecientes a las delegaciones de Cañada Honda y San Marcos
                          </div>
                        </div>
                        */}

                    </div>

                  </>
                }

              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Boletines;