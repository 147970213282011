import React, { useEffect } from 'react';

const BannerImagenes = () => {

  let imagesBanner = {
    "images": [
      {
        "id": 1,
        "target": "",
        "src": "images/banner/1.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/2.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/3.jpg"
      },
      {
        "id": 1,
        "target": "",
        "src": "images/banner/4.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/5.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/6.jpg"
      },
      {
        "id": 1,
        "target": "",
        "src": "images/banner/7.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/8.jpg"
      },
      {
        "id": 2,
        "target": "",
        "src": "images/banner/9.jpeg"
      },
      {
        "id": 1,
        "target": "",
        "src": "images/banner/10.jpeg"
      }
    ]
  };

  useEffect(() => {

    let totalImages = imagesBanner.images.length;
    let actualImgae = 1;

    let $banner = document.getElementById("banner");

    $banner.innerHTML = "";

    let $div = document.createElement("div");
    let $img = document.createElement("img");

    $img.setAttribute("src", imagesBanner.images[0].src);
    $img.setAttribute("width", "100%");
    $img.setAttribute("height", "700px");

    $div.appendChild($img);
    $banner.appendChild($div);

    setInterval(() => {

      $div = document.createElement("div");
      $img = document.createElement("img");

      $img.setAttribute("src", imagesBanner.images[actualImgae].src);
      $img.setAttribute("width", "100%");
      $img.setAttribute("height", "700px");


      $div.appendChild($img);
      $banner.innerHTML = "";
      $banner.appendChild($div);

      actualImgae++;

      if (actualImgae >= totalImages)
        actualImgae = 0;


    }, 4000);

  }, [])



  return (
    <div className="cell cell-1">
      <div id="banner">

      </div>
    </div>
  );
}

export default BannerImagenes;