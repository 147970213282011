import React from 'react'
import { NavLink } from "react-router-dom";
import ico_pdf from "../../images/ico_pdf.png";
function ContabilidadGubernamental_1() {
    return (
        <>
            <section id="grl_contenido">
                <div style={{ marginTop: "-100px" }}>
                <div style={{ clear: "both" }}></div>
                <div className="con1">
                    <div className="table-responsive">
                    <section id="ags-secction-contenido_general">
                        <div id="ContentPlaceHolder1_encabezado">
                        <div className='encabezado'>
                            <NavLink to="/contabilidadGubernamental">
                            <h2 to="/contabilidadGubernamental">Contabilidad Gubernamental
                            <span className='pib'>|</span>
                            </h2>
                            </NavLink>
                        </div>
                        </div>
                        <div id="ContentPlaceHolder1_linksurl">
                        <div className='linksurl'>
                            <NavLink to="/">
                            <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                            </NavLink>
                        </div>
                        </div>
                        <hr />
                        <div id="ContentPlaceHolder1_contenido_bd">
                        <div className="table-responsive" id="contenedor_tablas">  
                            
                            <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                    <span style={{ color: "#2d62bf" }}>1er Trimestre 2024</span>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                                    Formatos:
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    &nbsp; Documento:
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Endeudamiento_neto.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Endeudamiento_neto.pdf">
                                    &nbsp; Endeudamiento Neto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analiticio_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_economica.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analiticio_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_economica.pdf">
                                    &nbsp; Estado Analiticio del ejercicio del presupuesto de Egresos por clasificación económica
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_de_ingresos.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_de_ingresos.pdf">
                                    &nbsp; Estado analítico de ingresos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_de_la_deuda_y_otros_pasivos.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_de_la_deuda_y_otros_pasivos.pdf">
                                    &nbsp; Estado analitico de la deuda y otros pasivos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_del_activo.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_analitico_del_activo.pdf">
                                    &nbsp; Estado analítico del activo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_administrativa.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_administrativa.pdf">
                                    &nbsp; Estado Analitico del ejercicio del presupuesto de Egresos por clasificación administrativa
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_funcional.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_de_Egresos_por_clasificacion_funcional.pdf">
                                    &nbsp; Estado Analitico del ejercicio del presupuesto de Egresos por clasificación funcional
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_deEgresos_por_clasificacion_por_objeto_del_gasto.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_Analitico_del_ejercicio_del_presupuesto_deEgresos_por_clasificacion_por_objeto_del_gasto.pdf">
                                    &nbsp; Estado Analitico del ejercicio del presupuesto de Egresos por clasificación por objeto del gasto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_actividades.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_actividades.pdf">
                                    &nbsp; Estado de actividades
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_cambios_en_la_situacion_financiera.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_cambios_en_la_situacion_financiera.pdf">
                                    &nbsp; Estado de cambios en la situación financiera
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_flujos_de_efectivo.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_flujos_de_efectivo.pdf">
                                    &nbsp; Estado de flujos de efectivo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_situacion_financiera.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_situacion_financiera.pdf">
                                    &nbsp; Estado de situación financiera
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_variacion_en_la_hacienda_publica.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_variacion_en_la_hacienda_publica.pdf">
                                    &nbsp; Estado de variación en la hacienda pública
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Gasto_por_categoria_programatica.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Gasto_por_categoria_programatica.pdf">
                                    &nbsp; Gasto por categoría programática
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Intereses_de_la_deuda.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Intereses_de_la_deuda.pdf">
                                    &nbsp; Intereses de la deuda
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Notas_a_los_Estados_Financieros.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Notas_a_los_Estados_Financieros.pdf">
                                    &nbsp; Notas a los Estados Financieros
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Pasivos_Contingentes.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Pasivos_Contingentes.pdf">
                                    &nbsp; Pasivos Contingentes
                                    </a>
                                </td>
                                </tr>


                            </tbody>
                            </table>
                            
                            <br /><br />

                        </div>

                        
                        
                        </div>

                    </section>
                    </div>
                </div>

                </div>
            </section>
        </>
    )
}

export default ContabilidadGubernamental_1