import React from 'react';
//import logo_fas from "../images/home/banners/veda-banner_gris.jpg";
import logo_fas from "../images/home/banners/logo_fas.png";
import licitaciones from "../images/home/banners/licitaciones.png";
import BannerImagenes from '../componentes/BannerImagenes';
import Boletines from '../componentes/Boletines';
import BannerTransparencia from '../componentes/BannerTransparencia';
import { NavLink } from 'react-router-dom';


const Index = () => {
  return (
    <>

      <section id="sec_inicial" className="section sectionIndex">

        <div className="container">

          <BannerImagenes />

          <div className="cell cell-2">
            <div id="cell_cont2">
              <a href="https://fas.ccapama.gob.mx" rel="noreferrer" target="_blank">
                <div className="bg-CAMImage" style={{ cursor: "pointer" }} id="cont2_CAM">
                  <img alt="fondo de apoyo social" style={{ marginTop: "-2rem" }} src={logo_fas} />
                </div>
              </a>
              <NavLink to="/licitaciones">
                <div className="bg-lightgray" id="cont2_tramites">
                  <img style={{ marginTop: "30px" }} alt="" src={licitaciones} />
                </div>
              </NavLink>
            </div>
          </div>

          <Boletines />

          <BannerTransparencia />

          <div className="cell cell-5">
            <NavLink to="/ccapama">
              <div id="banner01_cont">
                <h2 style={{ marginTop: "2rem" }}><span style={{ color: "white" }}>¿QUIÉNES SOMOS?</span></h2>
              </div>
            </NavLink>
            <NavLink to="/contacto">
              <div className="cell_banner5" id="banner01"></div>
            </NavLink>
          </div>

          <div className="cell cell-6">
            <a href="https://www.ags.gob.mx/" target="_blank" style={{ cursor: "pointer" }}>
              <div style={{ marginTop: "2.5rem" }} className="cell_banner6" id="banner02"></div>
            </a>
          </div>

        </div>

      </section>

      {/*
      <section id="sec_servicios" className="section sectionIndex">


        <div className="div_servicios" id="bg_cam">
          <div id="sec_servicios_cont_tramites">
            <div id="sec_servicios_cont_cam">
              <a href="https://www.ags.gob.mx/cam/" target="_blank">
                <img alt="Centro de Atención Municipal CAM" src={logo_cam_intro} /></a>
              <br />
              <a href="https://www.ags.gob.mx/cam/index.aspx#secondPage" target="_blank">
                <div id="btn_depTramites"></div></a>
              <div id="ContentPlaceHolder1_panel2" style={{ float: "left" }}>

                <input name="ctl00$ContentPlaceHolder1$home_txt_cam" type="text" id="ContentPlaceHolder1_home_txt_cam" className="box_busqueda" placeholder="Buscar trámite" />
                <input type="submit" name="ctl00$ContentPlaceHolder1$home_btn_cam" value="" id="ContentPlaceHolder1_home_btn_cam" className="btn_busqueda" />

              </div>
            </div>
            <div id="sec_servicios_cont_camBuscados">
              <h3 style={{ color: "#cbd500" }}>Trámites más buscados:</h3>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=Licencias de Construcción#Page01R" target="_blank">
                <div className="tramites_alineados">

                  <img src="sitio_imgs/icons_tramites/ico_cam_t_licencia.png" alt="" />
                  <span>Licencias de<br />Construción</span>

                </div>
              </a>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=cepas#Page01R" target="_blank">
                <div className="tramites_alineados">
                  <img src="sitio_imgs/icons_tramites/ico_cam_t_obrasdecepas.png" alt="" />
                  <span>Obras de Cepas</span>
                </div>
              </a>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=fiestas#Page01R" target="_blank">
                <div className="tramites_alineados">
                  <img src="sitio_imgs/icons_tramites/ico_cam_t_fiestasTrad.png" alt="" />
                  <span>Permiso para Fiestas Tradicionales</span>
                </div>
              </a>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=civil#Page01R" target="_blank">
                <div className="tramites_alineados">
                  <img src="sitio_imgs/icons_tramites/ico_cam_t_proteCivil.png" alt="" />
                  <span>Protección Civil</span>
                </div>
              </a>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=compatibilidad#Page01R" target="_blank">
                <div className="tramites_alineados">
                  <img src="sitio_imgs/icons_tramites/ico_cam_t_compUrban.png" alt="" />
                  <span>Compatibilidad<br />Urbanística</span>
                </div>
              </a>
              <a href="https://www.ags.gob.mx/cam/index.aspx?busquedaGeneral=inapam#Page01R" target="_blank">
                <div className="tramites_alineados">
                  <img src="sitio_imgs/icons_tramites/ico_cam_t_credenINAPAM.png" alt="" />
                  <span>Credenciales INAPAM</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="div_servicios">
          <div className="containerServicios">
            <div className="cell cell-serv1">
              <a href="https://www.ags.gob.mx/hagamosequipo/" target="_blank">
                <div className="elementos_alineados_1 elementos_alineados_1_top">
                  <img className="bannerServiciosTop" src={btn_hagamosequipo} alt="" />
                </div>
              </a>
            </div>
            <div className="cell cell-serv2">
              <a href="https://www.ags.gob.mx/servicios2/sare/default.aspx" target="_blank">
                <div className="elementos_alineados_1 elementos_alineados_1_top">
                  <img className="bannerServiciosTop" src={btn_sare} alt="" />
                </div>
              </a>
            </div>
            <div className="cell cell-serv3">
              <a href="https://epagosmunicipio.ags.gob.mx/Acceso.aspx" target="_blank">
                <div className="elementos_alineados_1">
                  <img className="bannerServiciosTop" src={btn_facturacion} alt="" />
                </div>
              </a>
            </div>
            <div className="cell cell-serv4">
              <a href="https://www.ags.gob.mx/servicios/ecompras/menuecompras.asp" target="_blank">
                <div className="elementos_alineados_1">
                  <img className="bannerServiciosTop" src={btn_ecompras} alt="" />
                </div>
              </a>
            </div>
            <div className="cell cell-serv5">
              <div id="serv5_cont">
                <a href="https://www.ags.gob.mx/reglamentos/" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_reglamentos} alt="" />
                    <span>Reglamentos</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/servicios/estrados/index.aspx" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_estrados} alt="" />
                    <span>Estrados</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/avaluos/" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_avaluos} alt="" />
                    <span>Avalúos</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/siac/login.aspx" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_atencionCiudadana} alt="" />
                    <span>Atención Ciudadana</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/servicios/peritos/default.aspx" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_peritos} alt="" />
                    <span>Peritos/as</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/servicios/pisabit/login.asp" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_estrados} alt="" />
                    <span>PISABIT</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/cont.aspx?p=2504" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_concurso} alt="" />
                    <span>Concursos</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/PadronMascotas/" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_padronMascotas} alt="" />
                    <span>Padrón de<br />Mascotas</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/cont.aspx?p=1874" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_subasta} alt="" />
                    <span>Subastas</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/cont.aspx?p=4804" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_cartillas} alt="" />
                    <span>Cartillas S.M.N.</span>
                  </div>
                </a>
                <a href="https://www.ags.gob.mx/cont.aspx?p=1917" target="_blank">
                  <div className="elementos_alineados_3">
                    <img src={ico_convocatoria} alt="" />
                    <span>Licitaciones y Convocatorias</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

      </section>


      <section id="sec_municipio" className="section sectionIndex">

        <div className="containerTuMunicipio">

          <div className="cell cell-mun1">
            <div className="mun1_cont">
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/cont.aspx?p=1182">
                  <img src={ico_historia} alt="" />
                  <span>Historia de tu Municipio</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/cont.aspx?p=1195">
                  <img src={ico_emergencias} alt="" />
                  <span>Teléfonos de Emergencia</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/delegaciones">
                  <img src={ico_delegaciones} alt="" />
                  <span>Delegaciones</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/cont.aspx?p=4824">
                  <img src={ico_centrosTec} alt="" />
                  <span>Centros Tecnológicos</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/cont.aspx?p=841">
                  <img src={ico_biblioteca} alt="" />
                  <span>Bibliotecas Públicas</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/internet_gratuito/">
                  <img src={ico_internet} alt="" />
                  <span>Puntos de Internet Gratuito</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/cont.aspx?p=321">
                  <img src={ico_biblioteca} alt="" />
                  <span>Leyendas de Aguascalientes</span>
                </a>
              </div>
              <div className="elementos_alineados_2">
                <a href="https://www.ags.gob.mx/sedesom/ManualDelCiclistaAguascalientes.pdf" target="_blank">
                  <img src={regla_movilidad} alt="" />
                  <span>Manual del Ciclista Aguascalientes</span>
                </a>
              </div>
            </div>
          </div>


          <div className="cell cell-mun2">
            <div id="TuMunicipio_video" style={{ position: "relative" }}>
              <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FMunicipioAgs%2Fvideos%2F878405963163963%2F&show_text=false&width=560&t=0" width="560" height="314" style={{ border: "none", overflow: "hiddem" }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>

          <a href="https://www.implanags.gob.mx/" target="_blank">
            <div className="cell cell-mun3">
              <div className="div_misrositios">
                <img className="logo_dependencia" alt="IMPLAN" src={logo_implan} />
              </div>
              <div className="micrositioMas">
                <img alt="Ir al sitio" src={btn_vm} />
              </div>
            </div>
          </a>

          <a href="https://imac.gob.mx/" target="_blank">
            <div className="cell cell-mun4">
              <div className="div_misrositios">
                <img className="logo_dependencia" alt="IMPLAN" src={logo_imac} />
              </div>
              <div className="micrositioMas">
                <img alt="Ir al sitio" src={btn_vm} />
              </div>
            </div>
          </a>

          <a href="https://www.ags.gob.mx/turismo/" target="_blank">
            <div className="cell cell-mun5">
              <div className="div_misrositios">
                <img className="logo_dependencia" alt="IMPLAN" src={logo_turismo} />
              </div>
              <div className="micrositioMas">
                <img alt="Ir al sitio" src={btn_vm} />
              </div>
            </div>
          </a>

          <a href="https://www.ccapama.gob.mx/" target="_blank">
            <div className="cell cell-mun6">
              <div className="div_misrositios">
                <img className="logo_dependencia" alt="IMPLAN" src={logo_ccapama} />
              </div>
              <div className="micrositioMas">
                <img alt="Ir al sitio" src={btn_vm} />

              </div>
            </div>
          </a>

        </div>

      </section>

      <section id="sec_precidencia" className="section sectionIndex">
        <div className="div_servicios" id="presidencia_alcalde">

          <div id="presidencia_fotoAlcalde">

          </div>

          <div id="presidencia_infoAcalde">
            <div id="infoAcalde_cont">
              <h2>Leonardo Montañez Castro</h2>
              <p>Presidente Municipal 2021-2024</p>
            </div>
            <div id="infoAcalde_redes">
              <ul>
                <li>
                  <a href="https://www.facebook.com/leo.montanez.752" target="_blank"><img alt="" src={ico_redes_face} /></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/leomontanezags/" target="_blank"><img alt="" src={ico_redes_insta} /></a>
                </li>
                <li>
                  <a href="https://twitter.com/LeoMontanezC" target="_blank"><img alt="" src={ico_redes_twitter} /></a>
                </li>
              </ul>
            </div>
            <div id="infoAcalde_mas">
              <a href="https://www.ags.gob.mx/cont.aspx?p=7820">
                <img alt="Conoce al alcalde" src={btn_vm_over} />
              </a>
            </div>
          </div>

        </div>

        <div className="div_servicios">
          <div id="tuPresindecia_cont" className="mun1_cont">


            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cont.aspx?p=2542">
                <img src={ico_presi_regidores} alt="" />
                <span>Síndicos y Regidores</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cont.aspx?p=2922">
                <img src={ico_presi_regidores} alt="" />
                <span>Sala de Prensa</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/calidad/POLÍTICA CALIDAD 21-24.pdf">
                <img src={ico_presi_regidores} alt="" />
                <span>Política de Calidad</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cont.aspx?p=5009">
                <img src={ico_presi_regidores} alt="" />
                <span>Mejora Regulatoria</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/becas/2022/LISTA BECAS DE MOVILIDAD.pdf">
                <img src={ico_presi_regidores} alt="" />
                <span>Becas de Movilidad</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cont.aspx?p=6350">
                <img src={ico_presi_regidores} alt="" />
                <span>Manuales de Organizacón</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cont.aspx?p=1850">
                <img src={ico_presi_regidores} alt="" />
                <span>Directorio Municipal</span>
              </a>
            </div>
            <div className="elementos_alineados_2">
              <a href="https://www.ags.gob.mx/cedulas-peritos/">
                <img src={ico_presi_regidores} alt="" />
                <span>Cédulas de Identificación de Peritos</span>
              </a>
            </div>


          </div>


          <div id="BannerPresidencia">
            <a href="https://www.ags.gob.mx/PDM/PDM-2021-2024_12ene22.pdf" target="_blank">
              <img alt="Plan de Desarrollo Municipal 2021-2024" src={Banner_PDM} />
            </a>
          </div>

        </div>

      </section>
      */}


    </>
  );
}

export default Index;