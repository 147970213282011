import React from 'react';

const TramitesYServicios = () => {
  return (
    <>
      <section id="grl_contenido">
        <div>
          <div style={{clear: "both"}}></div>
          <div className="con1" style={{marginTop: "40px"}}>
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_contenido_bd">
                  <div align="justify">
                    <div align="justify">
                      <p className="boletines" style={{fontSize: "1.5rem"}}>
                        Manual de Trámites y Servicios
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        <a href="/tramites-y-servicios/manual-tramites-y-servicios.pdf" target="_blank">• Manual de Trámites y Servicios al Público (PDF)</a>
                      </h6>
                      <p className="boletines" style={{fontSize: "1.5rem", marginTop: "3rem"}}>
                        Procuraduría de Defensa al Usuario
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        <a href="/tramites-y-servicios/TA-CAS-001.pdf" target="_blank">• Credencialización para descuentos en el consumo de agua a pensionados/as, jubilados/as, personas de la 3ra. edad y personas de escasos recursos (PDF)</a>
                      </h6>
                      <p className="boletines" style={{fontSize: "1.5rem", marginTop: "3rem"}}>
                        Dirección Técnica
                      </p>
                      <h6 style={{fontSize:"1.2rem"}}>
                        <a href="/tramites-y-servicios/formatoTramitePermiso2018.pdf" target="_blank">• Solicitud de permiso de descarga de aguas residuales al alcantarillado del Municipio de Aguascalientes (PDF)</a>
                        <br /> <br />
                        <a href="/tramites-y-servicios/TA-CAS-003.pdf" target="_blank">• Solicitud de factibilidad de servicios de agua potable y alcantarillado para desarrollos tipo habitacional, comercial, industrial y de servicios (PDF)</a>
                        <br /> <br />
                        <a href="/tramites-y-servicios/TA-CAS-004.pdf" target="_blank">• Solicitud de factibilidad de servicios de agua potable y alcantarillado para subdivisión (PDF)</a>
                        <br /> <br />
                        <a href="/tramites-y-servicios/TA-CAS-005.pdf" target="_blank">• Solicitud de anuencia para licencia de construcción (PDF)</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TramitesYServicios;