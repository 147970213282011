import React from 'react';
import { NavLink } from 'react-router-dom';


const ContabilidadGubernamental = () => {
  return (
    <div className="w-full h-full" id="grl_contenido">
      <section className="w-full h-full con1">
        <div className="w-full h-full flex justify-center items-center mt-52">
          <h1 className="text-black text-4xl">
            Contabilidad Gubernamental 2024
          </h1>

          <NavLink to="/contabilidadGubernamental/primertrimestre" className="text-green-500">
            <h5 className=" underline text-green-500">Primer Trimestre</h5>
          </NavLink>
          <NavLink to="/contabilidadGubernamental/segundotrimestre" className="text-green-500">
            <h5 className=" underline text-green-500">Segundo Trimestre</h5>
          </NavLink>
        </div>
      </section>
    </div>
  );
}

export default ContabilidadGubernamental;