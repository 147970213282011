import React from 'react'
import { NavLink } from "react-router-dom";
import ico_pdf from "../../images/ico_pdf.png";
function ContabilidadGubernamental_2() {
    return (
        <>
            <section id="grl_contenido">
                <div style={{ marginTop: "-100px" }}>
                <div style={{ clear: "both" }}></div>
                <div className="con1">
                    <div className="table-responsive">
                    <section id="ags-secction-contenido_general">
                        <div id="ContentPlaceHolder1_encabezado">
                        <div className='encabezado'>
                            <NavLink to="/contabilidadGubernamental">
                            <h2 to="/contabilidadGubernamental">Contabilidad Gubernamental
                            <span className='pib'>|</span>
                            </h2>
                            </NavLink>
                        </div>
                        </div>
                        <div id="ContentPlaceHolder1_linksurl">
                        <div className='linksurl'>
                            <NavLink to="/">
                            <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                            </NavLink>
                        </div>
                        </div>
                        <hr />
                        <div id="ContentPlaceHolder1_contenido_bd">
                        <div className="table-responsive" id="contenedor_tablas">  
                            
                            <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                                    <span style={{ color: "#2d62bf" }}>2do Trimestre 2024</span>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                                    Formatos:
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    &nbsp; Documento:
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/01-Estado_de_situacion_financiera_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/01-Estado_de_situacion_financiera_2do_trim_2024.pdf">
                                    &nbsp; Estado de Situacion Financiera 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_actividades.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Estado_de_actividades.pdf">
                                    &nbsp; Estado de Actividades
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/03-Estado_de_variación_en_la_hacienda_pública_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/03-Estado_de_variación_en_la_hacienda_pública_2do_trim_2024.pdf">
                                    &nbsp; Estado de Variacion en la Hacienda Publica
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/04-Estado_de_cambios_en_la_situación_financiera_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/04-Estado_de_cambios_en_la_situación_financiera_2do_trim_2024.pdf">
                                    &nbsp; Estado de Cambios en la Situacion Financiera
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/05-Estado_de_flujos_de_efectivo_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/05-Estado_de_flujos_de_efectivo_2do_trim_2024.pdf">
                                    &nbsp; Estado de Flujos de Efectivo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/06-Estado_analítico_del_activo_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/06-Estado_analítico_del_activo_2do_trim_2024.pdf">
                                    &nbsp; Estado Analitico Activo
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/07-Estado_analitico_de_la_deuda_y_otros_pasivos_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/07-Estado_analitico_de_la_deuda_y_otros_pasivos_2do_trim_2024.pdf">
                                    &nbsp; Estado Analitico de la Deuda y Otros Pasivos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/08-Pasivos Contingentes_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/08-Pasivos Contingentes_2do_trim_2024.pdf">
                                    &nbsp; Pasivos Contingentes
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/09-Notas a los Estados Financieros_2do trim 2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/09-Notas a los Estados Financieros_2do trim 2024.pdf">
                                    &nbsp; Notas a los Estados Financieros
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/10-Estado_analítico_de_ingresos_2019_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/10-Estado_analítico_de_ingresos_2019_2do_trim_2024.pdf">
                                    &nbsp; Estado Analitico de Ingresos
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/11-Egresos_por_clasificación_administrativa_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/11-Egresos_por_clasificación_administrativa_2do_trim_2024.pdf">
                                    &nbsp; Egresos por Clasificacion Administrativa
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/12-Egresos_por_clasificación_por_objeto_del_gasto_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/12-Egresos_por_clasificación_por_objeto_del_gasto_2do_trim_2024.pdf">
                                    &nbsp; Egresos por Clasificacion Objeto del Gasto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/13-Egresos_por_clasificación_económica_por_tipo_de_gasto_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/13-Egresos_por_clasificación_económica_por_tipo_de_gasto_2do_trim_2024.pdf">
                                    &nbsp; Egresos por Clasificacion Economica
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/14-Egresos_por_clasificación_funcional_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/14-Egresos_por_clasificación_funcional_2do_trim_2024.pdf">
                                    &nbsp; Egresos por Clasificacion Funcional
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/15-Endeudamiento_neto_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/15-Endeudamiento_neto_2do_trim_2024.pdf">
                                    &nbsp; Endeudamiento Neto
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Intereses-de-la-deuda.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Intereses-de-la-deuda.pdf">
                                    &nbsp; Intereses de la Deuda
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/17-Flujo_De_Fondos_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/17-Flujo_De_Fondos_2do_trim_2024.pdf">
                                    &nbsp; Flujo de Fondos 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/18-Indicadores_de_postura_fiscal_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/18-Indicadores_de_postura_fiscal_2do_trim_2024.pdf">
                                    &nbsp; Indicadores de Postura Fiscal 
                                    </a>
                                </td>
                                </tr>

                                <tr>
                                <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/19-Gasto_por_categoría_programática_2do_trim_2024.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/19-Gasto_por_categoría_programática_2do_trim_2024.pdf">
                                    &nbsp; Gasto por Categoria Programatica 
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", textAlign:"center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Indicadores-de-Resultados.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/Indicadores-de-Resultados.pdf">
                                    &nbsp; Indicadores de Resultados
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td style={{ height: "50px", textAlign:"center", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INVENTARIOS.pdf">
                                    <img alt="pdf" src={ico_pdf} />
                                    </a>
                                </td>
                                <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                                    <a href="/contabilidadGubernamental/INVENTARIOS.pdf">
                                    &nbsp; Inventarios
                                    </a>
                                </td>
                                </tr>


                            </tbody>
                            </table>
                            
                            <br /><br />

                        </div>

                        
                        
                        </div>

                    </section>
                    </div>
                </div>

                </div>
            </section>
        </>
    )
}

export default ContabilidadGubernamental_2