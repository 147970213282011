import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ico_pdf from '../images/ico_pdf.png';
import LicitacionesEspecial from './LicitacionesEspecial';

const Licitaciones = () => {

  const [licitaciones, setLicitaciones] = useState({});

  useEffect(() => {
    fetch('/licitaciones.json')
      .then((data) => data.json())
      .then((data) => {
        setLicitaciones(data);
      });
  }, []);


  useEffect(() => {

    if (licitaciones) {

      let $divContenedor = document.getElementById("contenedor_tablas");
      $divContenedor.innerHTML = "";
      let $table;
      let $tbody;
      let $trHead;
      let $tdTitulo;
      let $spanTitulo;
      let $tdFormato;
      let $tdArchivo;
      let $trEncabezados;

      for (let i = 0; i < licitaciones.length; i++) {
        //console.log(licitaciones[i].id);

        $table = document.createElement("table");
        $tbody = document.createElement("tbody");
        $trHead = document.createElement("tr");
        $trEncabezados = document.createElement("tr");
        $tdTitulo = document.createElement("td");
        $spanTitulo = document.createElement("span");
        $tdFormato = document.createElement("td");
        $tdArchivo = document.createElement("td");


        $spanTitulo.innerText = licitaciones[i].nombre;


        $table.classList.add("tabTrans");
        $table.classList.add("table");
        $table.classList.add("table-hover");
        $table.style.margin = "0px auto";
        $table.width = "100%";
        $table.setAttribute("cellspacing", "0");
        $table.setAttribute("cellpadding", "0");

        $tdTitulo.setAttribute("colspan", "3");
        $tdTitulo.style.height = "50px";
        $tdTitulo.style.border = "1px solid #DCDCDC";
        $tdTitulo.style.textAlign = "center";
        $tdTitulo.style.backgroundColor = "#F2F2F2";
        $tdTitulo.style.fontSize = "1.3rem";

        $spanTitulo.style.color = "#2d62bf";

        $tdFormato.innerText = "Formato";
        $tdArchivo.innerText = "Documento";

        $tdFormato.style.height = "50px";
        $tdFormato.style.width = "120px";
        $tdFormato.style.maxWidth = "120px";
        $tdFormato.style.border = "1px solid #DCDCDC";
        $tdFormato.style.textAlign = "center";

        $tdArchivo.style.textAlign = "leaft";
        $tdArchivo.style.border = "1px solid #DCDCDC";
        $tdArchivo.style.paddingLeft = "10px";

        $tdTitulo.appendChild($spanTitulo);
        $trHead.appendChild($tdTitulo);

        $trEncabezados.appendChild($tdFormato);
        $trEncabezados.appendChild($tdArchivo);


        $tbody.appendChild($trHead);
        $tbody.appendChild($trEncabezados);

        /*
        let $tdContenidoFormato = document.createElement("td");
        let $tdContenidoArchivo = document.createElement("td");

        $tdContenidoFormato.innerText = "1";
        $tdContenidoArchivo.innerText = "2";

        $trArchivo.appendChild($tdContenidoFormato);
        $trArchivo.appendChild($tdContenidoArchivo);

        $tbody.appendChild($trArchivo);
        */



        for (let j = 0; j < licitaciones[i].archivos.length; j++) {

          let $trArchivo = document.createElement("tr");
          let $tdContenidoFormato = document.createElement("td");
          let $tdContenidoArchivo = document.createElement("td");
          let $aArchivo = document.createElement("a");
          let $imgArchivo = document.createElement("img");
          let $aTexto = document.createElement("a");

          $aTexto.setAttribute("href", licitaciones[i].archivos[j].ruta);
          $aTexto.innerText = licitaciones[i].archivos[j].archivo;
          $aTexto.setAttribute("target", "_blank");
          $aTexto.style.color = "#000";

          //$tdContenidoArchivo.innerText = licitaciones[i].archivos[j].archivo;

          $tdContenidoFormato.style.height = "50px";
          $tdContenidoFormato.style.textAlign = "center";
          $tdContenidoFormato.style.border = "1px solid #DCDCDC";

          $tdContenidoArchivo.style.textAlign = "leaft";
          $tdContenidoArchivo.style.border = "1px solid #DCDCDC";
          $tdContenidoArchivo.style.paddingLeft = "10px";


          $aArchivo.setAttribute("href", licitaciones[i].archivos[j].ruta);
          $aArchivo.setAttribute("target", "_blank");
          //$aArchivo.innerText = licitaciones[i].archivos[j].formato;
          //$aArchivo.style.color = "#000";
          $imgArchivo.src = ico_pdf;

          $aArchivo.appendChild($imgArchivo);
          $tdContenidoFormato.appendChild($aArchivo);

          $trArchivo.appendChild($tdContenidoFormato);

          $tdContenidoArchivo.appendChild($aTexto);

          $trArchivo.appendChild($tdContenidoArchivo);

          $tbody.appendChild($trArchivo);
          
        }


        $table.appendChild($tbody);
        $divContenedor.appendChild($table);


      }
    }


  }, [licitaciones])




  return (
    <>
      <section id="grl_contenido">
        <div style={{ marginTop: "-200px" }}>
          <div style={{ clear: "both" }}></div>
          <div className="con1">
            <div className="table-responsive">
              <section id="ags-secction-contenido_general">
                <div id="ContentPlaceHolder1_encabezado">
                  <div className='encabezado'>
                    <h2>Licitaciones
                      <span className='pib'>|</span>
                    </h2>
                  </div>
                </div>
                <div id="ContentPlaceHolder1_linksurl">
                  <div className='linksurl'>
                    <NavLink to="/">
                      <img src="/images/boletines/linkhome.png" style={{ width: "16px", height: "14px" }} />
                    </NavLink>
                  </div>
                </div>
                <hr />
                <div id="ContentPlaceHolder1_contenido_bd">

                  

                  <div className="table-responsive" id="contenedor_tablas">  

                    {/*
                    <table className="tabTrans table table-hover" style={{ margin: "0px auto", width: "100%" }} cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td colSpan="3" style={{ height: "50px", border: "1px solid #DCDCDC", textAlign: "center", backgroundColor: "#F2F2F2" }}>
                            <span style={{ color: "#2d62bf" }}>Ordenamientos Federales, Estatales y Municipales</span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: "50px", width: "120px", minWidth: "80px", maxWidth: "120px", border: "1px solid #DCDCDC", textAlign: "center" }}>
                            Formatos:
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                            &nbsp; Documento:
                          </td>
                        </tr>

                        <tr>
                          <td style={{ height: "50px", textAlign: "center", border: "1px solid #DCDCDC" }}>
                            <a href="/transparencia/docs/art55/fracc 1/3T 2022 A55 F1 SHAYDGG Normatividad aplicable.xlsx">
                              <img alt="pdf" src="/transparencia/imgs/ico36_xls.png" />
                            </a>
                          </td>
                          <td style={{ textAlign: "leaft", border: "1px solid #DCDCDC" }}>
                            &nbsp; Marco Normativo | Tercer Trimestre 2022.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    */}
                    <br /><br />

                  </div>

                  <LicitacionesEspecial />
                  
                </div>

              </section>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Licitaciones;